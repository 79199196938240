import React, { useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/constants.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import { Container } from "@/game-lol/components/MinimapDeathsTimeline.style.jsx";
import type { DeathOutcome } from "@/game-lol/utils/deaths-performance-types.mjs";
import { DEATH_TYPES } from "@/game-lol/utils/deaths-performance-utils.mjs";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

const MAP_SIZE = 15000;
const MAP_IMAGE = `${appURLs.CDN_PLAIN}/league/blitz-lol-minimap-3.svg`;
const MAX_DEATHS_TO_DISPLAY = 6;

function getEventURL(eventType: string, platesAlive?: number) {
  switch (eventType) {
    case "TURRET_PLATE_DESTROYED":
      return platesAlive
        ? `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/turret_${platesAlive}plate.png`
        : `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/turret_5plate.png`;
    case "GRUB":
      return `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/grub.png`;
    case "BUILDING_KILL":
      return `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/tower.png`;
    case "DRAGON":
      return `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/dragon.png`;
    case "BARON_NASHOR":
      return `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/baron.png`;
    case "ELDER_DRAGON":
      return `${appURLs.CDN}/blitz/lol/minimap/lcu-client-images/dragon_elder.png`;
    default:
      return null;
  }
}

function MinimapDeathsTimeline({
  championId,
  deaths = [],
}: {
  championId: number;
  deaths: DeathOutcome[];
}) {
  const { t } = useTranslation();
  const [selectedDeathIndex, setSelectedDeathIndex] = useState<number | null>(
    null,
  );

  const deathsToDisplay = deaths
    .sort((a, b) => a.winProbabilityChange - b.winProbabilityChange)
    .slice(0, MAX_DEATHS_TO_DISPLAY)
    .sort((a, b) => a.timestamp - b.timestamp);
  const mostImpactfulDeath = [...deathsToDisplay].sort(
    (a, b) => a.winProbabilityChange - b.winProbabilityChange,
  )[0];

  const mostImpactfulDeathIndex = deathsToDisplay.findIndex(
    (death) => death.timestamp === mostImpactfulDeath.timestamp,
  );

  const selectedDeath =
    deathsToDisplay[selectedDeathIndex ?? mostImpactfulDeathIndex ?? 0];

  function handleClick(index: number) {
    if ("startViewTransition" in globals.document) {
      /* eslint-disable @typescript-eslint/no-explicit-any */ (
        globals.document as any
      ).startViewTransition(() => {
        flushSync(() => {
          setSelectedDeathIndex(index);
        });
      });
      return;
    }

    setSelectedDeathIndex(index);
  }

  if (!selectedDeath) {
    return (
      <Container>
        <div className="minimap-container"></div>
      </Container>
    );
  }

  const mapYAxisSegment =
    selectedDeath.position.y > 9750
      ? "top"
      : selectedDeath.position.y < 4250
        ? "bot"
        : "mid";

  return (
    <Container>
      <div className="mini-map-mask-container" data-y-segment={mapYAxisSegment}>
        <div className="minimap-container" style={{ "--map-size": MAP_SIZE }}>
          <div
            className="map-background"
            style={{ backgroundImage: `url(${MAP_IMAGE})` }}
          />
          {selectedDeath.consequenceEvents.map((event) => {
            const isADeath = event.type === "CHAMPION_KILL";
            return (
              <div
                key={event.timestamp}
                {...classNames("map-marker", isADeath && "other-death-marker")}
                style={{
                  "--x": event.position.x,
                  "--y": event.position.y,
                }}
              >
                {isADeath ? (
                  <div className="champion-img-container">
                    <ChampionImg
                      championId={event.misc.victimChampionId}
                      size={20}
                      className="champion-img"
                    />
                  </div>
                ) : (
                  <img
                    src={getEventURL(event.type, event.misc.platesAlive)}
                    width="28"
                    height="28"
                  />
                )}
              </div>
            );
          })}
          {selectedDeath && (
            <div
              className="map-marker local-player-death-marker"
              data-x-position={selectedDeath.position.x}
              data-y-position={selectedDeath.position.y}
              style={{
                "--x": selectedDeath.position.x,
                "--y": selectedDeath.position.y,
              }}
            >
              <div className="champion-img-container">
                <ChampionImg
                  championId={championId}
                  size={24}
                  className="champion-img"
                />
              </div>
            </div>
          )}
        </div>
        {selectedDeath && (
          <DeathsDetailsPanel
            death={selectedDeath}
            mostImpactfulDeath={mostImpactfulDeath}
          />
        )}
      </div>
      <div className="timeline-container">
        {deathsToDisplay.map((death, index) => {
          const isSelected = selectedDeath?.timestamp === death.timestamp;
          const timestamp = `@${death.mins}:${
            death.seconds < 10 ? `0${death.seconds}` : death.seconds
          }`;
          const gold = `${(death.goldTotal * -1).toLocaleString(getLocale())}g`;
          const isMostImpactful =
            death.timestamp === mostImpactfulDeath.timestamp;
          const deathType = DEATH_TYPES[death.type];
          return (
            <button
              key={death.timestamp}
              onClick={() => handleClick(index)}
              className="timeline-item type-caption--bold"
              style={{ "--x": death.position.x }}
              data-selected={isSelected}
              data-most-impactful={isMostImpactful}
              data-tip={
                isSelected
                  ? t("common:selectedDeath", "Selected Death")
                  : t("common.viewDeadDetails", "View Death Details")
              }
            >
              {isMostImpactful && (
                <div className="most-impactful-marker">{"⚠️"}</div>
              )}
              <div className="timeline-item-marker" />
              <span className="timeline-item-label">
                <span>
                  {t(...deathType.label)} {timestamp}
                </span>
                <br />
                <span>{gold}</span>
              </span>
            </button>
          );
        })}
      </div>
    </Container>
  );
}

function DeathsDetailsPanel({
  death,
  mostImpactfulDeath,
}: {
  death: DeathOutcome;
  mostImpactfulDeath: DeathOutcome;
}) {
  const { t } = useTranslation();
  const timestamp = `@${death.mins}:${
    death.seconds < 10 ? `0${death.seconds}` : death.seconds
  }`;
  const deathType = DEATH_TYPES[death.type];

  return (
    <div
      className="death-details-panel"
      data-location={death.position.x <= MAP_SIZE / 2 ? "right" : "left"}
    >
      <span className="type-caption--bold">
        {death.timestamp === mostImpactfulDeath.timestamp ? (
          <span>
            {t(
              "common:coaching.mostImpactfulDeathLabelTimestamp",
              "⚠️ Most impactful Death: {{label}} {{timestamp}}",
              {
                label: t(...deathType.label),
                timestamp,
              },
            )}
          </span>
        ) : (
          <span>
            {t(...deathType.label)} {timestamp}
          </span>
        )}
      </span>
      <div className="death-details-contents">
        <span className="death-amount type-large-title--bold">
          {t("lol:amountGoldSwing", "{{goldAmount}} Gold Swing", {
            goldAmount: death.goldTotal.toLocaleString(getLocale()),
          })}
        </span>
        <DeathDetailsList death={death} />
      </div>
    </div>
  );
}

function formatPercentage(value: number) {
  return value.toLocaleString(getLocale(), {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
}

function DeathDetailsList({ death }: { death: DeathOutcome }) {
  const { t } = useTranslation();

  const List: React.ReactNode[] = [];

  List.push(
    <li key="win-probability">
      <span style={{ color: "var(--shade0)" }}>
        {t(
          "common:coaching.valueWinProbabilityChange",
          "{{value}} Win Probabilty Change",
          { value: formatPercentage(death.winProbabilityChange) },
        )}
      </span>
    </li>,
  );

  List.push(
    <li key="kill-value">
      <span>
        {t("common:coaching.plusValueKillValue", "+{{value}}g: Kill Value", {
          value: death.gold + death.goldAssists,
        })}
      </span>
    </li>,
  );

  if (death.minionsLost) {
    List.push(
      <li key="minions-lost">
        {t(
          "lol:coaching.plusValueMissedCountMinions",
          "+{{value}}g: Missed ~{{count}} Minions",
          {
            value: death.goldCS,
            count: death.minionsLost,
          },
        )}
      </li>,
    );
  }

  const towerPlates = death.consequenceEvents
    .filter((event) => event.type === "TURRET_PLATE_DESTROYED")
    .reduce(
      (acc, event) => {
        acc.count += 1;
        acc.goldTotal += event.goldTotal;
        return acc;
      },
      { count: 0, goldTotal: 0 },
    );
  if (towerPlates.count > 0) {
    List.push(
      <li key="tower-plates">
        {t(
          "lol:coaching.plusValueCountTowerPlatesLost",
          "+{{value}}g: {{count}} Tower plate(s) lost",
          {
            value: towerPlates.goldTotal,
            count: towerPlates.count,
          },
        )}
      </li>,
    );
  }

  const tower = death.consequenceEvents.find(
    (event) => event.type === "BUILDING_KILL",
  );
  if (tower) {
    List.push(
      <li key="tower">
        <span>
          {t(
            "lol:coaching.plusValueEnemyDestroyedNearbyTower",
            "+{{value}}g: Enemy destroyed a nearby tower",
            {
              value: tower.goldTotal,
            },
          )}
        </span>
      </li>,
    );
  }

  const teammateDeaths = death.consequenceEvents
    .filter((event) => event.type === "CHAMPION_KILL")
    .reduce(
      (acc, event) => {
        acc.count += 1;
        acc.goldTotal += event.goldTotal;
        acc.timestamp = event.timestamp;
        if (event.typeCount === 4 || event.typeCount === 6) {
          acc.breakpoint = event.typeCount;
        }
        return acc;
      },
      { count: 0, goldTotal: 0, breakpoint: 0, timestamp: 0 },
    );
  if (teammateDeaths.count > 0) {
    const timeDifference = teammateDeaths.timestamp - death.timestamp;
    List.push(
      <li key="teammate-deaths">
        {t(
          "lol:coaching.plusValueCountTeammateDiedTime",
          "+{{value}}g: {{count}} teammate(s) died ~{{time}}s after",
          {
            value: teammateDeaths.goldTotal,
            count: teammateDeaths.count,
            time: Math.round(timeDifference / 6000),
          },
        )}
      </li>,
    );
  }

  const grubs = death.consequenceEvents
    .filter((event) => event.type === "GRUB")
    .reduce(
      (acc, event) => {
        acc.count += 1;
        acc.goldTotal += event.goldTotal;
        if (event.typeCount === 4 || event.typeCount === 6) {
          acc.breakpoint = event.typeCount;
        }
        return acc;
      },
      { count: 0, goldTotal: 0, breakpoint: 0 },
    );
  if (grubs.count > 0) {
    List.push(
      <li key="grubs">
        {grubs.breakpoint
          ? t(
              "lol:coaching.plusValueGrubsLostStackCount",
              "+{{value}} grubs lost. Enemy Team's {{count}}th grubs stack",
              {
                value: grubs.goldTotal,
                count: grubs.breakpoint,
              },
            )
          : t("lol:coaching.valueGrubsLost", "+{{value}} grubs lost")}
      </li>,
    );
  }

  const dragon = death.consequenceEvents.find(
    (event) => event.type === "DRAGON",
  );
  if (dragon) {
    const count = {
      1: t("common:placementOrdinals.first.short", "1st"),
      2: t("common:placementOrdinals.second.short", "2nd"),
      3: t("common:placementOrdinals.third.short", "3rd"),
      4: t("common:placementOrdinals.fourth.short", "4th"),
    };
    List.push(
      <li key="dragon">
        {t("lol:coaching.countEnemyDragon", "{{count}} Enemy dragon", {
          count: count[dragon?.typeCount ?? 1],
        })}
      </li>,
    );
  }
  const elderDragon = death.consequenceEvents.find(
    (event) => event.type === "ELDER_DRAGON",
  );
  if (elderDragon) {
    List.push(
      <li key="elder-dragon">
        {t("lol:coaching.enemyTookElderDragon", "Enemy took Elder Dragon")}
      </li>,
    );
  }
  const baron = death.consequenceEvents.find(
    (event) => event.type === "BARON_NASHOR",
  );
  if (baron) {
    List.push(
      <li key="baron">
        {t("lol:coaching.enemyTookBaronNashor", "Enemy took Baron Nashor")}
      </li>,
    );
  }

  return <ul className="death-details-list type-caption--semi">{List}</ul>;
}

export default MinimapDeathsTimeline;
