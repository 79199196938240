import { styled } from "goober";

export const Container = styled("div")`
  & {
    /* display: grid;
    gap: var(--sp-4); */
  }

  .mini-map-mask-container {
    --map-height: 15rem;
    position: relative;
    height: var(--map-height);
    overflow: clip;
    border: 1px solid var(--shade3-25);
    border-radius: var(--br-xl);

    &[data-y-segment="top"] .minimap-container {
      top: 0;
    }
    &[data-y-segment="mid"] .minimap-container {
      top: 50%;
      translate: 0 -50%;
    }
    &[data-y-segment="bot"] .minimap-container {
      top: 100%;
      translate: 0 -100%;
    }
  }
  .minimap-container {
    position: absolute;
    width: 100%;
    height: auto;
    margin-inline: auto;
    aspect-ratio: 1;
    transition:
      top 0.25s linear,
      translate 0.25s linear;

    .map-background {
      position: absolute;
      inset: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: var(--shade8);
    }

    .map-marker {
      position: absolute;
      bottom: calc(var(--y) / var(--map-size) * 100%);
      left: calc(var(--x) / var(--map-size) * 100%);
      translate: -50% 50%;
      /* width: 5%; */
      /* background: red; */
      border-radius: 50%;

      &.death {
      }
    }
    .local-player-death-marker,
    .other-death-marker {
      border-radius: 50%;
      border: 2px solid var(--red);

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%;
        background: var(--red);
        opacity: 0.75;
        mix-blend-mode: color;
        backdrop-filter: grayscale(1);
      }

      .champion-img {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        border-radius: 50%;
      }
    }
    .local-player-death-marker {
      view-transition-name: death-marker;
      width: 2.25rem;
      box-shadow: 0 0 10px 5px var(--shade10);
    }
    .other-death-marker {
      width: 1.5rem;
      opacity: 0.5;
    }
  }

  .timeline-container {
    --marker-size: var(--sp-2);
    --marker-padd: var(--sp-4);
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-inline: auto;

    &:not(:has(:nth-child(4))) {
      justify-content: space-around;
      gap: 0;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed var(--shade5);
      top: calc(var(--marker-padd) + var(--marker-size) / 2);
      translate: 0 -100%;
    }
  }
  .timeline-item {
    --color: var(--shade2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--sp-2);
    padding: var(--marker-padd);
    text-align: center;
    color: var(--color);
    background: transparent;
    cursor: pointer;
    transition: color var(--transition);

    &:hover,
    &[data-selected="true"] {
      --color: var(--shade0);
    }
    &[data-selected="true"] {
      .timeline-item-marker::after {
        opacity: 0.75;
        scale: 1;
      }
    }
    &[data-most-impactful="true"] {
    }
  }
  .timeline-item-marker {
    position: relative;
    width: var(--marker-size);
    height: var(--marker-size);
    background: var(--color);
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 175%;
      height: 175%;
      border: 1px solid var(--color);
      border-radius: 50%;
      translate: -50% -50%;
      opacity: 0;
      scale: 0.5;
      transition:
        opacity var(--transition),
        scale var(--transition);
    }
  }
  .most-impactful-marker {
    position: absolute;
    top: var(--marker-padd);
    translate: -110% -35%;
  }

  .death-details-panel {
    --inset: 0;
    view-transition-name: death-details-panel;
    position: absolute;
    top: var(--inset);
    left: 0;
    display: grid;
    gap: var(--sp-1);
    width: 100%;
    max-width: 32ch;
    padding: var(--sp-4);
    padding-block-end: var(--sp-12);
    text-shadow: 0 3px 5px var(--shade10);
    background: linear-gradient(to bottom, var(--shade8) 50%, transparent);
    border-radius: var(--br-xl);
    z-index: 2;

    &[data-location="right"] {
      text-align: right;
      left: unset;
      right: var(--inset);
      background: radial-gradient(
        ellipse at top right,
        var(--shade8) 20%,
        transparent 70%
      );
    }

    &[data-location="left"] {
      left: var(--inset);
      right: unset;
      background: radial-gradient(
        ellipse at top left,
        var(--shade8) 20%,
        transparent 70%
      );
    }
  }
  .death-details-contents {
    display: grid;
    padding-inline-start: var(--sp-1);

    .death-amount {
      color: var(--red);
    }
    .death-details-list {
      list-style: none;
      color: var(--shade0-75);
    }
  }
`;
