import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import MinimapDeathsTimeline from "@/game-lol/components/MinimapDeathsTimeline.jsx";
import RunesStatsCard from "@/game-lol/components/RunesStatsCard.jsx";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import LolColors from "@/game-lol/constants/colors.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import { getPreferredPostmatchComparisonRank } from "@/game-lol/utils/actions.mjs";
import { coachingPerformance } from "@/game-lol/utils/coaching-utils.mjs";
import {
  PlayerDeathsSubtitle,
  PlayerDeathsTitle,
} from "@/game-lol/utils/deaths-performance-feedback.jsx";
import { analyzePlayerDeaths } from "@/game-lol/utils/deaths-performance-utils.mjs";
import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import RuneIcon from "@/inline-assets/hextech-rune-icon.svg";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";
import PostMatchCoachingSection from "@/shared/PostMatchCoachingSection.jsx";
import { RankValueCompare } from "@/shared/PostMatchCoachingSection.style.jsx";
import { statPerformance } from "@/util/coaching.mjs";
import { devLog } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function PostMatchCoaching({ localPlayer, match, timeline }) {
  const { t } = useTranslation();

  const cardRefs = useRef([]);

  const {
    lol: { championStatsByMin },
    volatile: { lolCurrentSkinSelection },
  } = useSnapshot(readState);

  const [cardBgImg, setCardBgImg] = useState(null);

  const matchQueueSymbol = QueueSymbol(match.queueId);
  const preferredComparisonRank = getPreferredPostmatchComparisonRank();
  const playerRankSymbol =
    preferredComparisonRank || localPlayer.rank || DEFAULT_COMPARISON_RANK;
  const playerRankDetails = RANK_SYMBOL_TO_STR[playerRankSymbol];
  const playerRankName = t(
    playerRankDetails.t.name,
    playerRankDetails.t.fallback,
  );
  const playerRankColors = LolColors.ranks?.[playerRankDetails?.key];
  const playerRankLabel = [
    playerRankDetails.t.name,
    playerRankDetails.t.fallback,
  ] as Translation;
  const RankIcon = getHextechRankIcon(playerRankSymbol);
  const nextRankSymbol = playerRankDetails.nextRank;
  const nextRankDetails = nextRankSymbol
    ? RANK_SYMBOL_TO_STR[nextRankSymbol]
    : null;

  const playerRoleSymbol = RoleSymbol(localPlayer.teamPosition);
  const playerRole = ROLE_SYMBOL_TO_STR[playerRoleSymbol];

  const champions = getStaticData("champions");
  const championKey = champions?.keys?.[localPlayer.championId];
  const championName = champions?.[championKey]?.name;

  const coachingVars = coachingStatsVariables({
    championId: localPlayer.championId,
    queue: QUEUE_SYMBOLS.rankedSoloDuo,
    tier: playerRankSymbol,
    role: playerRoleSymbol,
  });

  const benchmarkData =
    championStatsByMin?.[coachingStatsKey(coachingVars)]?.divisionStats?.[0];

  const {
    loading: loadingCoaching,
    categories,
    _runesStats,
  } = coachingPerformance({
    player: localPlayer,
    match,
    benchmarkData,
    queue: matchQueueSymbol,
  });

  const {
    loading: loadingPlayerDeaths,
    playerDeaths,
    eventTypes,
  } = analyzePlayerDeaths({
    localPlayer,
    timeline,
    match,
  });

  useEffect(() => {
    if (!localPlayer?.championId) return;

    if (
      lolCurrentSkinSelection?.selectedChampionId === localPlayer.championId
    ) {
      setCardBgImg(
        Static.getChampionCenterSkinImage(
          localPlayer.championId,
          lolCurrentSkinSelection?.selectedSkinId,
        ),
      );
    } else {
      setCardBgImg(Static.getChampionCenterImage(localPlayer.championId));
    }
  }, [localPlayer?.championId, lolCurrentSkinSelection]);

  const playerChampionKey = champions?.keys?.[localPlayer.championId];
  const playerChampion = champions?.[playerChampionKey];

  devLog("[Postmatch Coaching] Player Deaths:", playerDeaths);
  devLog("[Postmatch Coaching] Event Types:", eventTypes);

  const performanceCards = categories.map((category, i) => {
    const Icon = category.icon;
    return {
      titleShort: category.title,
      icon: <Icon />,
      component: (
        <PerformanceCard
          ref={cardRefs.current[i]}
          title={category.title}
          score={category.performance.score}
          infoTooltip={t(...[category.explanation])}
          rankColor={playerRankColors?.fill || "var(--shade1)"}
          subContent={
            <PerformanceInputStats
              rows={category.stats.map((stat) => {
                const { score } = stat;
                const tooltipDescription: Translation =
                  score.val < score.mean
                    ? [
                        "common:coaching.youDidAmountWorseThanAverageRankCharacter",
                        "You did {{amount}} worse than the average {{rank}} {{character}}",
                        {
                          amount: (1 - score.val / score.mean).toLocaleString(
                            getLocale(),
                            {
                              style: "percent",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          ),
                          rank: playerRankName,
                          character: championName,
                        },
                      ]
                    : [
                        "common:coaching.youDidAmountBetterThanAverageRankCharacter",
                        "You did {{amount}} better than the average {{rank}} {{character}}",
                        {
                          amount: (score.val / score.mean - 1).toLocaleString(
                            getLocale(),
                            {
                              style: "percent",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          ),
                          rank: playerRankName,
                          character: championName,
                        },
                      ];

                return {
                  label: Array.isArray(stat.title)
                    ? t(...stat.title)
                    : stat.title,
                  valueDisplay: score.val.toLocaleString(
                    getLocale(),
                    stat.format,
                  ),
                  value: score.val,
                  format: stat.format,
                  fill: score.score,
                  min: score.min,
                  max: score.max,
                  mean: score.mean,
                  tooltipOptions: {
                    description: tooltipDescription,
                    min: {
                      label: [
                        "lol:rankNameBot25Percent",
                        "{{rankName}} Bot 25%",
                        {
                          rankName: playerRankName,
                        },
                      ],
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                    max: {
                      label: [
                        "lol:rankNameTop25Percent",
                        "{{rankName}} Top 25%",
                        {
                          rankName: playerRankName,
                        },
                      ],
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                    mean: {
                      label: [
                        "lol:rankNameAvg",
                        "{{rankName}} Avg",
                        {
                          rankName: playerRankName,
                        },
                      ],
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                  },
                };
              })}
            />
          }
        />
      ),
    };
  });
  performanceCards.push({
    titleShort: ["lol:runes.runes", "Runes"],
    icon: <RuneIcon />,
    component: <RunesStatsCard player={localPlayer} />,
  });

  const isLoading = loadingCoaching || loadingPlayerDeaths;

  if (isLoading) {
    return <Card loading style={{ height: 804 }} />;
  }

  return (
    <PostMatchCoachingSection
      backgroundImage={{
        src: cardBgImg,
        onError: (ev) => {
          if (!localPlayer?.championId) return;
          const defaultImg = Static.getChampionCenterImage(
            localPlayer?.championId,
          );
          const target = ev.target as HTMLImageElement;
          if (target.src !== defaultImg) setCardBgImg(defaultImg);
        },
        style: {
          width: "60rem",
          height: "auto",
          top: 0,
          right: 0,
          translate: "25% -20%",
        },
      }}
      rankComparison={{
        icon: <RankIcon width="28" height="28" />,
        color: playerRankDetails.color,
        titleSnippet: t(
          "lol:rankChampionRole",
          "{{rankName}} {{championName}} {{roleName}}",
          {
            rankName: t(...playerRankLabel),
            championName: playerChampion?.name || "",
            roleName: playerRole?.label && t(...playerRole.label),
          },
        ),
        rankName: t(playerRankDetails.t.name, playerRankDetails.t.fallback),
        rankValue: 4771,
      }}
      title={{
        text: (
          <PlayerDeathsTitle
            matchMins={Math.floor(match.gameDuration / 60)}
            playerDeaths={playerDeaths}
            playerRankString={
              playerRankDetails &&
              t(playerRankDetails.t.name, playerRankDetails.t.fallback)
            }
            playerNextRankString={
              nextRankDetails &&
              t(nextRankDetails.t.name, nextRankDetails.t.fallback)
            }
            benchmarkData={benchmarkData}
          />
        ),
        spanColor: RandomColor(),
      }}
      subtitle={{
        text: <PlayerDeathsSubtitle playerDeaths={playerDeaths} />,
      }}
      supertitle={"Deaths Performance"}
      performanceCards={performanceCards}
    >
      <MinimapDeathsTimeline
        championId={localPlayer.championId}
        deaths={playerDeaths}
      />
      <GoldPerMinuteComparison
        localPlayer={localPlayer}
        playerRankName={t(...playerRankLabel)}
        playerRankColor={playerRankDetails.color}
        RankIcon={RankIcon}
        playerChampionName={playerChampion?.name}
        playerRoleName={t(...playerRole.label)}
        timeline={timeline}
        benchmarkData={benchmarkData}
      />
    </PostMatchCoachingSection>
  );
}

export default PostMatchCoaching;

function RandomColor() {
  const colors = [
    "var(--yellow)",
    "var(--purple)",
    "var(--orange)",
    "var(--lime)",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

function GoldPerMinuteComparison({
  localPlayer,
  playerRankName,
  playerRankColor,
  RankIcon,
  playerChampionName,
  playerRoleName,
  timeline,
  benchmarkData,
}) {
  // HIDE FOR NOW
  return null;

  const { t } = useTranslation();

  const playerGold: number =
    timeline?.frames?.[14]?.participantFrames?.[localPlayer.participantId]
      ?.totalGold;

  const benchmarkGold = benchmarkData?.totalGoldByMinute?.[14];
  const benchmarkRange = statPerformance({
    playerVal: playerGold,
    benchmarkMean: benchmarkGold?.mean,
    benchmarkStdev: benchmarkGold?.stdev,
  });

  const items = [
    {
      value: playerGold || 0,
      fill: benchmarkRange.score || 0.15,
      image: (
        <ChampionImg
          id={localPlayer.championId}
          size={36}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      ),
      label: t("common:you", "You"),
    },
    {
      value: Math.round(benchmarkRange?.max) || 0,
      fill: 1,
      image: RankIcon && <RankIcon />,
      label: playerRankName,
      color: playerRankColor,
    },
  ];

  const diff = items[0].value - items[1].value;
  const isNegative = diff < 0;
  const diffColor = isNegative ? "var(--red)" : "var(--green)";

  return (
    <RankValueCompare>
      <p className="pre-title type-subtitle--semi">
        {isNegative ? (
          <Trans i18nKey="lol:coaching:deaths.resultedInLosingGold">
            These deaths resulted in you losing a lot of gold. Here’s how you
            compare to other{" "}
            <span>
              {playerRankName} {playerChampionName} {playerRoleName} Players
            </span>{" "}
            at the end of laning.
          </Trans>
        ) : (
          <Trans>
            You kept your gold income stable and did better than other{" "}
            <span>
              {playerRankName} {playerChampionName} {playerRoleName}
            </span>{" "}
            players
          </Trans>
        )}
      </p>
      <div className="stat-graphic">
        <span className="type-callout--semi">
          {t(
            "lol:goldEarnedAt14minsParens",
            "Gold earned @14mins (end of lane phase)",
          )}
        </span>
        {items.map((item, i) => {
          return (
            <div
              key={i}
              className="bar"
              style={{
                "--fill-percentage": item.fill,
                "--bar-color": item.color,
              }}
            >
              <div className="bar-left">{item.image}</div>
              <div className="bar-right">
                <span className="type-subtitle--semi">{item.label}</span>
                <span className="type-subtitle--bold">
                  {item.value.toLocaleString(getLocale())}
                </span>
              </div>
            </div>
          );
        })}
        <span
          className="diff type-title--bold"
          style={{ "--diff-color": diffColor }}
        >
          {isNegative
            ? diff.toLocaleString(getLocale())
            : `+${diff.toLocaleString(getLocale())}`}
        </span>
      </div>
    </RankValueCompare>
  );
}
