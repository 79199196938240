import React, {
  // useEffect,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { Trans, useTranslation } from "react-i18next";

import {
  CardsCarouselContainer,
  CoachingSection,
} from "@/shared/PostMatchCoachingSection.style.jsx";
import globals from "@/util/global-whitelist.mjs";

export type PostMatchCoachingSectionProps = {
  backgroundImage?: {
    src: string;
    onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    style?: React.CSSProperties;
  };
  rankComparison: {
    color: string;
    titleSnippet: string;
    rankName: string;
    rankValue: number;
    icon?: React.ReactNode;
  };
  title: {
    text: string | React.ReactNode;
    spanColor: string;
  };
  subtitle?: {
    text: string | React.ReactNode;
    spanColor?: string;
  };
  supertitle?: string;
  children: React.ReactNode;
  performanceCards?: PerformanceCard[];
};

type PerformanceCard = {
  titleShort: Translation;
  icon?: React.ReactNode;
  component: React.ReactNode;
};

function PostMatchCoachingSection({
  backgroundImage,
  rankComparison,
  title,
  subtitle,
  supertitle,
  children,
  // performanceCategories,
  performanceCards,
}: PostMatchCoachingSectionProps) {
  return (
    <CoachingSection
      style={{
        "--title-highlight": title.spanColor,
        "--subtitle-highlight": subtitle?.spanColor,
        "--rank-comparison-color": rankComparison.color,
      }}
    >
      {backgroundImage && (
        <img
          src={backgroundImage.src}
          onError={backgroundImage.onError}
          className="background-image"
          style={backgroundImage.style}
        />
      )}
      <header className="rank-comparison">
        {rankComparison.icon}
        <span className="rank-comparison-title">
          <Trans i18nKey="">
            Your performance compared to other{" "}
            <span>{rankComparison.titleSnippet}</span> Players
          </Trans>
        </span>
      </header>
      <div className="coaching-content">
        <CardsCarousel cards={performanceCards} />
        <div className="feedback">
          <div className="feedback-header">
            <div className="title-supertitle">
              <span className="supertitle type-title--semi">{supertitle}</span>
              <h3 className="title">{title.text}</h3>
            </div>
            {subtitle?.text && (
              <p className="subtitle type-subtitle--semi">{subtitle?.text}</p>
            )}
          </div>
          {children}
        </div>
      </div>
    </CoachingSection>
  );
}

function CardsCarousel({ cards }: { cards: PerformanceCard[] }) {
  const { t } = useTranslation();

  const [manuallySelectedIndex, setManuallySelectedIndex] = useState<
    number | null
  >(null);
  const [currentIndex, _setCurrentIndex] = useState(manuallySelectedIndex ?? 0);

  // useEffect(() => {
  //   if (!categories.length) return;
  //   const interval = setInterval(() => {
  //     if (globals.document.startViewTransition) {
  //   globals.document.startViewTransition(() => {
  //     flushSync(() => {
  //       setCurrentIndex((prevIndex) =>
  //         prevIndex === categories.length - 1 ? 0 : prevIndex + 1,
  //       );
  //     });
  //   });
  //       return;
  //     }
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === categories.length - 1 ? 0 : prevIndex + 1,
  //     );
  //   }, 2000); // 2 seconds
  //   return () => clearInterval(interval);
  // }, [cards.length]);

  function setManualIndex(index: number) {
    if ("startViewTransition" in globals.document) {
      /* eslint-disable @typescript-eslint/no-explicit-any */ (
        globals.document as any
      ).startViewTransition(() => {
        flushSync(() => {
          setManuallySelectedIndex(index);
        });
      });
      return;
    }

    setManuallySelectedIndex(index);
  }

  const activeIndex = manuallySelectedIndex ?? currentIndex;

  return (
    <CardsCarouselContainer>
      <div className="cards-stack">{cards[activeIndex].component}</div>
      <div className="cards-nav">
        {cards.map((category, i) => {
          return (
            <button
              key={i}
              onClick={() => setManualIndex(i)}
              data-active={i === activeIndex}
              className="cards-nav-button type-caption--semi"
            >
              {category.icon}
              <span>{t(...category.titleShort)}</span>
            </button>
          );
        })}
      </div>
    </CardsCarouselContainer>
  );
}

export default PostMatchCoachingSection;
