import React from "react";
import { Trans } from "react-i18next";

import type {
  DeathOutcome,
  // GamePhases,
} from "@/game-lol/utils/deaths-performance-types.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

function formatNumber(number: number, options: Intl.NumberFormatOptions = {}) {
  return number.toLocaleString(getLocale(), options);
}

export function PlayerDeathsTitle({
  matchMins,
  playerDeaths,
  playerRankString,
  playerNextRankString,
  benchmarkData,
}: {
  matchMins: number;
  playerDeaths: DeathOutcome[];
  playerRankString: string | undefined;
  playerNextRankString: string | undefined;
  benchmarkData: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}) {
  let title: React.JSX.Element = null;

  const benchmarkEndofLaneDeaths = benchmarkData?.deathsByMinute?.[14]?.mean;
  const benchmarkEOGDeaths =
    benchmarkData?.deathsByMinute?.[Math.floor(matchMins)]?.mean;

  const endOfLaneDeaths = playerDeaths.filter((d) => d.phase === "LANE").length;
  const _endOfMidDeaths = playerDeaths.filter((d) => d.phase === "MID").length;
  const totalDeaths = playerDeaths.length;

  const percentOptions: Intl.NumberFormatOptions = {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  };

  const endofLaneDeathsDifference =
    endOfLaneDeaths - Math.floor(benchmarkEndofLaneDeaths);
  const eogDeathsDifference = totalDeaths - Math.floor(benchmarkEOGDeaths);

  switch (true) {
    case totalDeaths === 0: {
      const winrateIncrease = Math.random() * (0.25 - 0.15) + 0.15; // 15% to 25%
      const winrateIncreasePercentage = formatNumber(
        winrateIncrease,
        percentOptions,
      );
      title = playerNextRankString ? (
        <Trans i18nKey="lol:coaching:deathsTitle.noDeathsIncreaseNextRank">
          You didn&apos;t die at all this game! This increased your chance of
          winning by <span>~{winrateIncreasePercentage}</span>. Keep it up and
          you&apos;ll be <span>{playerNextRankString}</span> in no time.
        </Trans>
      ) : (
        <Trans i18nKey="lol:coaching:deathsTitle.noDeathsIncrease">
          You didn&apos;t die at all this game! This increased your chance of
          winning by <span>~{winrateIncreasePercentage}</span>.
        </Trans>
      );
      break;
    }
    case eogDeathsDifference > 1: {
      title = (
        <Trans i18nKey="lol:coaching:deathsTitle.moreThanRankEOGDeaths">
          You died <span>{formatNumber(totalDeaths)} times</span> this game,
          thats <span>{formatNumber(eogDeathsDifference)} more times</span> than
          the average
          <span> {playerRankString} player</span>
        </Trans>
      );
      break;
    }
    case endofLaneDeathsDifference > 0: {
      title = (
        <Trans i18nKey="lol:coaching:deathsTitle.moreThanRankLaneDeaths">
          You died <span>{formatNumber(totalDeaths)} times</span> during lane,
          thats{" "}
          <span>{formatNumber(endofLaneDeathsDifference)} more times</span> than
          the average
          <span> {playerRankString} player</span>
        </Trans>
      );
      break;
    }
    default: {
      const winrateIncrease = Math.random() * (0.25 - 0.15) + 0.15; // 15% to 25%
      const winrateIncreasePercentage = formatNumber(
        winrateIncrease,
        percentOptions,
      );
      title = playerRankString ? (
        <Trans i18nKey="lol:coaching:deathsTitle.lowDeathsIncrease">
          Nice you only had <span>{formatNumber(totalDeaths)} deaths</span>{" "}
          increasing your chance to win by {winrateIncreasePercentage}.
        </Trans>
      ) : (
        <Trans i18nKey="lol:coaching:deathsTitle.lowDeathsIncrease">
          Nice, you kept your deaths to <span>{formatNumber(totalDeaths)}</span>
          !
        </Trans>
      );
      break;
    }
  }

  return title;
}

export function PlayerDeathsSubtitle({
  playerDeaths,
}: {
  playerDeaths: DeathOutcome[];
}) {
  void playerDeaths;

  return null;
}
