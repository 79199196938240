import { calcWinProbability } from "@/game-lol/utils/calc-win-probability.mjs";
import type {
  ConsequenceEvent,
  DeathOutcome,
  MatchParticipant,
  TimelineParticipant,
} from "@/game-lol/utils/deaths-performance-types.mjs";
import { ROLE_SYMBOLS } from "@/game-lol/utils/roles.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

const BLACKLIST_ITEMS = {
  3340: true, // trinket
  3363: true, // trinket
  3364: true, // trinket
  2003: true, // Health potion
  2031: true, // Refillable potion
  2055: true, // Control ward
  2138: true, // Elixir of Iron
  2139: true, // Elixir of Sorcery
  2140: true, // Elixir of Wrath
};
const SUPPORT_ITEMS = {
  3869: 3869, // Celestial opposition
  3870: 3870, // Dreammaker
  3871: 3871, // ZakZak
  3876: 3876, // Solstice sleigh
  3877: 3877, // bloodsong
};

export const DEATH_TYPES = {
  DEATH_ROAM: {
    key: "DEATH_ROAM",
    label: ["lol:deathType:enemyRoam", "Enemy Roam"],
  },
  DEATH_GANK: {
    key: "DEATH_GANK",
    label: ["lol:deathType:ganked", "Ganked"],
  },
  DEATH_SOLO: {
    key: "DEATH_SOLO",
    label: ["lol:deathType:soloKilled", "Solo Killed"],
  },
  DEATH_SOLO_UNDERLEVEL: {
    key: "DEATH_SOLO_UNDERLEVEL",
    label: ["lol:deathType:soloUnderlevel", "Solo Underlevel"],
  },
  DEATH_SKIRMISH: {
    key: "DEATH_SKIRMISH",
    label: ["lol:deathType:skirmish", "Skirmish"],
  },
  DEATH_TEAMFIGHT: {
    key: "DEATH_TEAMFIGHT",
    label: ["lol:deathType:teamfight", "Teamfight"],
  },
  DEATH_CAUGHT: {
    key: "DEATH_CAUGHT",
    label: ["lol:deathType:caught", "Caught"],
  },
};

function removeFromInventory(itemId, inventory = []) {
  const itemIndex = inventory.indexOf(itemId);
  if (itemIndex > -1) inventory.splice(itemIndex, 1);
}
function addToInventory(itemId, inventory = []) {
  if (BLACKLIST_ITEMS[itemId] || !itemId) return;
  inventory.push(itemId);
}

const TURRET_PLATE_GOLD_VALUE = 125;
const TOWER_GOLD_VALUE_LOCAL = 250;
const TOWER_GOLD_VALUE_GLOBAL = 50;

// 4000 is a reasonable radius around the dragon and baron
// Tool to visualize map coordinates:
// https://svelte.dev/playground/da740f65a62e4bffabbe7a7e2ec8aa5b?version=5.16.1
const MAX_DISTANCE_FOR_CONSEQUENCE_EVENT = 4000;

const TOWERS = [
  // team 100
  {
    x: 981,
    y: 10441,
    laneType: "TOP_LANE",
    towerType: "OUTER_TURRET",
    teamId: 100,
  },
  {
    x: 1512,
    y: 6699,
    laneType: "TOP_LANE",
    towerType: "INNER_TURRET",
    teamId: 100,
  },
  {
    x: 1169,
    y: 4287,
    laneType: "TOP_LANE",
    towerType: "BASE_TURRET",
    teamId: 100,
  },

  {
    x: 5846,
    y: 6396,
    laneType: "MID_LANE",
    towerType: "OUTER_TURRET",
    teamId: 100,
  },
  {
    x: 5048,
    y: 4812,
    laneType: "MID_LANE",
    towerType: "INNER_TURRET",
    teamId: 100,
  },
  {
    x: 3651,
    y: 3696,
    laneType: "MID_LANE",
    towerType: "BASE_TURRET",
    teamId: 100,
  },

  {
    x: 10504,
    y: 1029,
    laneType: "BOT_LANE",
    towerType: "OUTER_TURRET",
    teamId: 100,
  },
  {
    x: 6919,
    y: 1483,
    laneType: "BOT_LANE",
    towerType: "INNER_TURRET",
    teamId: 100,
  },
  {
    x: 4281,
    y: 1253,
    laneType: "BOT_LANE",
    towerType: "BASE_TURRET",
    teamId: 100,
  },

  // team 200
  {
    x: 4318,
    y: 13875,
    laneType: "TOP_LANE",
    towerType: "OUTER_TURRET",
    teamId: 200,
  },
  {
    x: 7943,
    y: 13411,
    laneType: "TOP_LANE",
    towerType: "INNER_TURRET",
    teamId: 200,
  },
  {
    x: 10481,
    y: 13650,
    laneType: "TOP_LANE",
    towerType: "BASE_TURRET",
    teamId: 200,
  },

  {
    x: 8955,
    y: 8510,
    laneType: "MID_LANE",
    towerType: "OUTER_TURRET",
    teamId: 200,
  },
  {
    x: 9767,
    y: 10113,
    laneType: "MID_LANE",
    towerType: "INNER_TURRET",
    teamId: 200,
  },
  {
    x: 11134,
    y: 11207,
    laneType: "MID_LANE",
    towerType: "BASE_TURRET",
    teamId: 200,
  },

  {
    x: 13866,
    y: 4505,
    laneType: "BOT_LANE",
    towerType: "OUTER_TURRET",
    teamId: 200,
  },
  {
    x: 13327,
    y: 8226,
    laneType: "BOT_LANE",
    towerType: "INNER_TURRET",
    teamId: 200,
  },
  {
    x: 13624,
    y: 10572,
    laneType: "BOT_LANE",
    towerType: "BASE_TURRET",
    teamId: 200,
  },
];

function towerKey(tower) {
  return `${tower.teamId}-${tower.laneType}-${tower.towerType}`;
}

function plateTower(plateEvent) {
  for (const tower of TOWERS) {
    if (
      tower.x === plateEvent.position.x &&
      tower.y === plateEvent.position.y
    ) {
      return tower;
    }
  }
}

const _ELITE_MONSTER_POS = [
  {
    x: 4950,
    y: 10550,
    key: "BARON_PIT",
  },
  {
    x: 10030,
    y: 4400,
    key: "DRAGON_PIT",
  },
];

const GRUB_COUNT_VALUES = {
  1: 50,
  2: 50,
  3: 50,
  4: 200,
  5: 50,
  6: 200,
};

const DRAGON_COUNT_VALUES = {
  1: 100,
  2: 100,
  3: 300,
  4: 1000,
};

function distance(pos1, pos2) {
  return Math.sqrt((pos2.x - pos1.x) ** 2 + (pos2.y - pos1.y) ** 2);
}

function isNearby(pos1, pos2, radius = 1000) {
  return distance(pos1, pos2) <= radius;
}

function calculateDeathTimer(championLevel, timestamp) {
  // Base timer by champion level
  const baseTimers = {
    1: 10,
    2: 10,
    3: 12,
    4: 12,
    5: 14,
    6: 16,
    7: 20,
    8: 25,
    9: 28,
    10: 32.5,
    11: 35,
    12: 37.5,
    13: 40,
    14: 42.5,
    15: 45,
    16: 47.5,
    17: 50,
    18: 52.5,
  };

  const minutes = timestamp / 60000;
  const minuteHalf = Math.floor(minutes / 0.5) * 0.5;

  // Get the base timer for the given champion level
  const baseTimer = baseTimers[championLevel];

  // Modify the timer based on game time (general rule: scales longer in late game)
  // https://wiki.leagueoflegends.com/en-us/Death
  let scalingFactor = 0;
  if (minutes >= 15 && minutes < 30) {
    scalingFactor = (0 + Math.ceil(2 * (minuteHalf - 15)) * 0.425) / 100;
  } else if (minutes >= 30 && minutes < 45) {
    scalingFactor = (12.75 + Math.ceil(2 * (minuteHalf - 30)) * 0.3) / 100;
  } else if (minutes >= 45 && minutes < 55) {
    scalingFactor = (21.75 + Math.ceil(2 * (minuteHalf - 45)) * 1.45) / 100;
  } else {
    scalingFactor = 0.5;
  }
  if (scalingFactor > 0.5) scalingFactor = 0.5;

  return Math.round(baseTimer + baseTimer * scalingFactor);
}

function calculateAvgMinionValue(timestamp) {
  const mins = timestamp / 60000;

  if (mins <= 15) return 19.8;
  else if (mins <= 17.25) return 22.6;
  else if (mins <= 25) return 23;
  return 27.6;
}

function calculateValueOfDeath({
  event,
  level,
  destroyedTowers,
  participantRoles,
  participantTeamIds,
}: {
  event: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  level: number;
  destroyedTowers: Set<string>;
  participantRoles: Map<number, symbol>;
  participantTeamIds: Map<number, number>;
}) {
  const {
    timestamp,
    victimId,
    killerId,
    assistingParticipantIds = [],
    bounty,
    shutdownBounty,
  } = event;
  const victimTeamId = participantTeamIds.get(victimId);
  const victimIsSupport =
    participantRoles.get(victimId) === ROLE_SYMBOLS.support;
  const opponentsInvolved = new Set([killerId, ...assistingParticipantIds]);

  const gold = bounty + shutdownBounty;
  const goldAssists = assistingParticipantIds.length
    ? Math.round(bounty / 2)
    : 0;

  // Determine if death is near tower
  let nearbyTower = null;
  for (const tower of TOWERS) {
    const isOwnTower = tower.teamId === victimTeamId;
    const isTowerDestroyed = !!destroyedTowers.has(towerKey(tower));

    if (isNearby(event.position, tower, 1000) && !isTowerDestroyed) {
      nearbyTower = {
        key: towerKey(tower),
        isOwnTower,
      };
      break;
    }
  }

  const deathDurationSec = calculateDeathTimer(level, timestamp);
  const timeToGetToLaneSec = 30; // seconds (ignorant of TP 😔)
  const totalTimeOffMapSec = deathDurationSec + timeToGetToLaneSec;
  const csPerMin = 7; // TODO: get this from frame stats
  const csPerSec = csPerMin / 60;

  let minionsLost = victimIsSupport
    ? 0
    : Math.round(totalTimeOffMapSec * csPerSec);
  if (nearbyTower?.isOwnTower) minionsLost = minionsLost * 2;
  const goldCS = Math.round(minionsLost * calculateAvgMinionValue(timestamp));

  return {
    gold,
    goldAssists,
    goldCS,
    goldTotal: gold + goldAssists + goldCS,
    deathDurationSec,
    totalTimeOffMapSec,
    minionsLost,
    level,
    opponentsInvolved,
    nearbyTower,
  };
}

const matchParticipant = {
  participantId: 0,
  puuid: "",
  teamPosition: "",
  championId: 0,
  teamId: 0,
  item0: 0,
  item1: 0,
  item2: 0,
  item3: 0,
  item4: 0,
  item5: 0,
  item6: 0,
};
const timelineParticipant = {
  participantId: 0,
  puuid: "",
};

export function analyzePlayerDeaths({
  localPlayer,
  timeline,
  match,
}: {
  localPlayer: MatchParticipant;
  timeline: {
    frames: any[] /* eslint-disable-line @typescript-eslint/no-explicit-any */;
    participants: TimelineParticipant[];
  };
  match: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}): {
  loading: boolean;
  playerDeaths: DeathOutcome[];
  eventTypes: Record<string, number>;
} {
  const role = RoleSymbol(localPlayer.teamPosition);
  const isDuoLane = role === ROLE_SYMBOLS.adc || role === ROLE_SYMBOLS.support;

  const matchPlayers = new Map<number, MatchParticipant>();
  const matchLaner = new Set<number>();
  let matchOpponentJungler: MatchParticipant = { ...matchParticipant };
  const opponentIds = new Set<number>();
  const teammateIds = new Set<number>();
  const championIds = new Map<number, number>();
  const participantRoles = new Map<number, symbol>();
  const participantTeamIds = new Map<number, number>();

  const matchParticipants: MatchParticipant[] = match?.participants || [];

  for (const p of matchParticipants) {
    const isOpponent = p.teamId !== localPlayer.teamId;
    matchPlayers.set(p.participantId, p);
    championIds.set(p.participantId, p.championId);
    participantRoles.set(p.participantId, RoleSymbol(p.teamPosition));
    participantTeamIds.set(p.participantId, p.teamId);

    if (isDuoLane) {
      if (
        (RoleSymbol(p.teamPosition) === ROLE_SYMBOLS.adc ||
          RoleSymbol(p.teamPosition) === ROLE_SYMBOLS.support) &&
        isOpponent
      ) {
        matchLaner.add(p.participantId);
      }
    } else if (p.teamPosition === localPlayer.teamPosition && isOpponent) {
      matchLaner.add(p.participantId);
    }

    if (RoleSymbol(p.teamPosition) === ROLE_SYMBOLS.jungle && isOpponent)
      matchOpponentJungler = p;
    if (p.teamId === localPlayer.teamId) {
      teammateIds.add(p.participantId);
    } else {
      opponentIds.add(p.participantId);
    }
  }

  let timelinePlayer: TimelineParticipant = timelineParticipant;
  // let timelineLaner: TimelineParticipant = timelineParticipant;

  const timelineParticipants: TimelineParticipant[] =
    timeline?.participants || [];

  for (const p of timelineParticipants) {
    if (p.puuid === localPlayer.puuid) timelinePlayer = p;
    // if (p.puuid === matchLaner.puuid) timelineLaner = p;
  }

  // void timelineLaner;

  const EOGItems = matchParticipants.reduce((acc, p) => {
    acc[p.participantId] = [
      p.item0,
      p.item1,
      p.item2,
      p.item3,
      p.item4,
      p.item5,
      p.item6,
    ];
    return acc;
  }, {});

  const frames = timeline?.frames || [];

  const inventories = {};
  const playerLevels = {};
  const turretPlatesAlive = {};
  const destroyedTowers = new Set<string>();
  const playerDeaths: DeathOutcome[] = [];
  const teamDragons: Record<number, string[]> = {};
  const teamGrubs = {};
  const lastDeathRespawnTime = {};
  const lastPurchaseTime = {};
  let totalKills = 0;

  const eventTypes: Record<string, number> = {};

  const allEvents = frames.reduce((acc, frame) => {
    acc.push(...(frame.events || []));
    return acc;
  }, []);

  for (const [eventIndex, event] of allEvents.entries()) {
    if (!eventTypes[event.type]) eventTypes[event.type] = 0;
    eventTypes[event.type]++;

    if (!inventories[event.participantId])
      inventories[event.participantId] = [];
    const playerInventory = inventories[event.participantId] || [];

    switch (event.type) {
      case "SKILL_LEVEL_UP":
        if (event.levelUpType !== "NORMAL") break;
        if (!playerLevels[event.participantId])
          playerLevels[event.participantId] = { value: 0, leveledAt: 0 };
        playerLevels[event.participantId].value++;
        playerLevels[event.participantId].leveledAt = event.timestamp;
        break;

      case "ELITE_MONSTER_KILL":
        // NOTE: teamId 300 seems to be when it despawns (should only be HORDE and RIFTHERALD)
        if (event.monsterType === "DRAGON") {
          if (!teamDragons[event.killerTeamId])
            teamDragons[event.killerTeamId] = [];
          teamDragons[event.killerTeamId].push(event.monsterSubType);
        } else if (event.monsterType === "HORDE") {
          if (!teamGrubs[event.killerTeamId])
            teamGrubs[event.killerTeamId] = [];
          teamGrubs[event.killerTeamId].push(event.timestamp);
        }

        break;

      case "ITEM_PURCHASED":
        lastPurchaseTime[event.participantId] = event.timestamp;
        if (BLACKLIST_ITEMS[event.itemId]) break;
        addToInventory(event.itemId, playerInventory);
        break;
      case "ITEM_UNDO":
        if (event.beforeId) {
          removeFromInventory(event.beforeId, playerInventory);
        }
        if (event.afterId) {
          addToInventory(event.afterId, playerInventory);
        }
        break;
      case "ITEM_DESTROYED":
        removeFromInventory(event.itemId, playerInventory);

        // certain items "transform" but dont appear as purchase events
        if (event.itemId === 3866) {
          // Support item transformation
          const finalItems = EOGItems[event.participantId] || [];
          addToInventory(
            finalItems.find((itemId) => SUPPORT_ITEMS[itemId]),
            playerInventory,
          );
        } else if (event.itemId === 3010) {
          // symbiotic souls
          addToInventory(3013, playerInventory);
        }
        break;
      case "ITEM_SOLD":
        removeFromInventory(event.itemId, playerInventory);
        break;

      case "TURRET_PLATE_DESTROYED": {
        const key = towerKey(plateTower(event));
        if (!turretPlatesAlive[key]) turretPlatesAlive[key] = 5;
        turretPlatesAlive[key]--;
        break;
      }

      case "BUILDING_KILL": {
        if (event.buildingType === "TOWER_BUILDING") {
          destroyedTowers.add(towerKey(event));
        }
        break;
      }

      case "CHAMPION_KILL": {
        const victimLevel = playerLevels[event.victimId]?.value || 1;
        const killerLevel = playerLevels[event.killerId]?.value || 1;

        const deathDurationMS =
          calculateDeathTimer(victimLevel, event.timestamp) * 1000;
        lastDeathRespawnTime[event.victimId] =
          event.timestamp + deathDurationMS;
        totalKills++;
        void totalKills;

        // Local player deaths
        if (event.victimId === timelinePlayer.participantId) {
          const { timestamp, position } = event;

          const mins = Math.floor(timestamp / 60000);
          const seconds = Math.round((timestamp / 1000) % 60);

          const frameStats = frames[mins]?.participantFrames || {};
          const players: { totalGold: number }[] = Object.values(frameStats);
          const avgTotalGold =
            players.reduce((acc, p) => acc + p.totalGold, 0) / players.length;
          const phase =
            timestamp / 60000 <= 14
              ? "LANE"
              : avgTotalGold <= 8000
                ? "MID"
                : "LATE";

          // 3055
          const teamGold = [...teammateIds].reduce((acc, id) => {
            return acc + frameStats[id].totalGold;
          }, 0);
          const opponentGold = [...opponentIds].reduce((acc, id) => {
            return acc + frameStats[id].totalGold;
          }, 0);
          const goldDiff = teamGold - opponentGold;

          const death: DeathOutcome = {
            timestamp,
            mins,
            seconds,
            phase,
            level: victimLevel,
            opponentsInvolved: new Set(),
            assistors: event.assistingParticipantIds || [],
            position,
            nearbyTower: null,
            levelVictim: victimLevel,
            levelKiller: killerLevel,
            consequenceEvents: [],

            gold: 0,
            goldAssists: 0,
            goldCS: 0,
            goldTotal: 0,
            deathDurationSec: 0,
            minionsLost: 0,
            type: "",
            winProbabilityChange: 0,
          };

          const {
            gold,
            goldAssists,
            goldCS,
            goldTotal,
            opponentsInvolved,
            deathDurationSec,
            minionsLost,
            nearbyTower,
          } = calculateValueOfDeath({
            event,
            level: victimLevel,
            destroyedTowers,
            participantRoles,
            participantTeamIds,
          });
          death.gold = gold;
          death.goldAssists = goldAssists;
          death.goldCS = goldCS;
          death.goldTotal = goldTotal;
          death.deathDurationSec = deathDurationSec;
          death.minionsLost = minionsLost;
          death.nearbyTower = nearbyTower;

          death.opponentsInvolved = opponentsInvolved;

          // Determine consequence events
          // (subsequent events that add gold to value of the death)
          const laterEvents = allEvents.slice(eventIndex + 1);
          for (const laterEvent of laterEvents) {
            if (laterEvent.timestamp > timestamp + 45000) break; // at most 45s later

            if (!laterEvent.position) continue;
            if (
              !isNearby(
                laterEvent.position,
                death.position,
                MAX_DISTANCE_FOR_CONSEQUENCE_EVENT,
              )
            )
              continue;

            const mins = Math.floor(laterEvent.timestamp / 60000);
            const seconds = Math.round((laterEvent.timestamp / 1000) % 60);

            const consequenceEvent: ConsequenceEvent = {
              type: laterEvent.type,
              timestamp: laterEvent.timestamp,
              mins,
              seconds,
              position: laterEvent.position,

              gold: 0,
              goldTotal: 0,
              misc: {},
            };

            switch (consequenceEvent.type) {
              case "CHAMPION_KILL": {
                if (!teammateIds.has(laterEvent.victimId)) break;
                const { gold, goldAssists, goldCS, goldTotal, nearbyTower } =
                  calculateValueOfDeath({
                    event: laterEvent,
                    level: victimLevel,
                    destroyedTowers,
                    participantRoles,
                    participantTeamIds,
                  });
                consequenceEvent.gold = gold;
                consequenceEvent.goldAssists = goldAssists;
                consequenceEvent.goldTotal = goldTotal;
                consequenceEvent.goldCS = goldCS;
                consequenceEvent.victimId = laterEvent.victimId;
                consequenceEvent.misc.victimChampionId = championIds.get(
                  laterEvent.victimId,
                );
                consequenceEvent.misc.nearbyTower = nearbyTower;
                death.goldTotal += goldTotal;
                death.consequenceEvents.push(consequenceEvent);
                break;
              }
              case "BUILDING_KILL": {
                if (laterEvent.teamId !== localPlayer.teamId) break;
                const towerGold =
                  TOWER_GOLD_VALUE_LOCAL + TOWER_GOLD_VALUE_GLOBAL * 5;
                consequenceEvent.gold = towerGold;
                consequenceEvent.goldTotal = towerGold;
                if (laterEvent.bounty)
                  consequenceEvent.gold += laterEvent.bounty;
                death.goldTotal += consequenceEvent.gold;
                death.consequenceEvents.push(consequenceEvent);
                break;
              }
              case "ELITE_MONSTER_KILL": {
                if (laterEvent.killerTeamId === localPlayer.teamId) break;
                const opponentAlive = Array.from(opponentIds).reduce(
                  (acc, t) => {
                    const isAlive =
                      (lastDeathRespawnTime[t] || 0) < laterEvent.timestamp;
                    if (isAlive) acc++;
                    return acc;
                  },
                  0,
                );
                let monsterValue = 0;
                if (laterEvent.monsterSubType === "ELDER_DRAGON") {
                  monsterValue = opponentAlive * 2500;
                  consequenceEvent.type = "ELDER_DRAGON";
                } else if (laterEvent.monsterType === "BARON_NASHOR") {
                  monsterValue = opponentAlive * 750;
                  consequenceEvent.type = "BARON_NASHOR";
                } else if (laterEvent.monsterType === "HORDE") {
                  const teamGrubsCount =
                    teamGrubs[laterEvent.killerTeamId]?.length || 0;
                  monsterValue = GRUB_COUNT_VALUES[teamGrubsCount + 1];
                  consequenceEvent.type = "GRUB";
                  consequenceEvent.typeCount = teamGrubsCount + 1;
                } else if (laterEvent.monsterType === "DRAGON") {
                  const enemyDragonCount =
                    teamDragons[laterEvent.killerTeamId]?.length || 0;
                  monsterValue = DRAGON_COUNT_VALUES[enemyDragonCount + 1];
                  consequenceEvent.type = "DRAGON";
                  consequenceEvent.typeCount = enemyDragonCount + 1;
                }
                consequenceEvent.gold = monsterValue;
                consequenceEvent.goldTotal = monsterValue;
                death.goldTotal += monsterValue;
                death.consequenceEvents.push(consequenceEvent);
                break;
              }
              case "TURRET_PLATE_DESTROYED": {
                // killerId is 0 for turret plates destroyed by minions
                // Ignoring this because we can't tell if it actually gave gold, assume it did
                // if (!laterEvent.killerId) break;
                if (plateTower(laterEvent)?.teamId !== localPlayer.teamId)
                  break;
                const platesAlive =
                  turretPlatesAlive[towerKey(plateTower(laterEvent))] ?? 5;
                consequenceEvent.gold = TURRET_PLATE_GOLD_VALUE;
                consequenceEvent.goldTotal = TURRET_PLATE_GOLD_VALUE;
                consequenceEvent.misc.platesAlive = platesAlive - 1;
                death.goldTotal += consequenceEvent.gold;
                death.consequenceEvents.push(consequenceEvent);
                break;
              }
            }
          }

          death.type = determineDeathType({
            mins,
            isDuoLane,
            death,
            matchOpponentJungler,
          });

          const winProbabilityBefore = calcWinProbability(mins, goldDiff);
          const winProbabilityAfter = calcWinProbability(
            mins,
            goldDiff - death.goldTotal,
          );
          const winProbabilityChange =
            winProbabilityAfter - winProbabilityBefore;

          death.winProbabilityChange = winProbabilityChange;

          playerDeaths.push(death);
        }
        break;
      }
    }
  }

  return {
    loading: !match || !timeline,
    playerDeaths,
    eventTypes,
  };
}

function determineDeathType({ mins, isDuoLane, death, matchOpponentJungler }) {
  let type = DEATH_TYPES.DEATH_CAUGHT.key;

  const {
    opponentsInvolved,
    consequenceEvents,
    phase,
    levelKiller,
    levelVictim,
  } = death;

  const junglerInvolved = opponentsInvolved.has(
    matchOpponentJungler.participantId,
  );

  const subsequentDeaths = consequenceEvents.filter(
    (event) => event.type === "CHAMPION_KILL",
  ).length;
  const numLaneOpponents = isDuoLane ? 2 : 1;
  const isSoloDeath =
    opponentsInvolved.size === numLaneOpponents ||
    (mins > 20 && opponentsInvolved.size === 1 && !subsequentDeaths);

  if (isSoloDeath) {
    if (!isDuoLane && levelKiller <= 6 && levelKiller > levelVictim)
      type = DEATH_TYPES.DEATH_SOLO_UNDERLEVEL.key;
    else type = DEATH_TYPES.DEATH_SOLO.key;
  } else if (phase === "LANE" && opponentsInvolved.size > numLaneOpponents) {
    if (!junglerInvolved) type = DEATH_TYPES.DEATH_ROAM.key;
    else type = DEATH_TYPES.DEATH_GANK.key;
  } else if (!subsequentDeaths) {
    type = DEATH_TYPES.DEATH_CAUGHT.key;
  } else if (opponentsInvolved.size > 3 || subsequentDeaths > 3) {
    type = DEATH_TYPES.DEATH_TEAMFIGHT.key;
  } else if (opponentsInvolved.size === 3) {
    type = DEATH_TYPES.DEATH_SKIRMISH.key;
  }

  return type;
}
